import React, { createRef } from 'react';
import { useFetchDeclaration } from '../../hooks/useDeclaration';
import { createArticleDetailPath } from '../../routing/urlGenerator/appUrlGenerator';
import useDetermineArticleToRedirectTo from './hooks/useDetermineArticleToRedirectTo';
import useDetermineIntroVideoHasPlayed from './hooks/useDetermineIntroVideoHasPlayed';
import { useDispatch } from 'react-redux';
import { Dispatch } from '../../redux/types';
import { Redirect } from 'react-router';
import styled from 'styled-components';

const StyledVideo = styled.video`
    position: fixed;
    z-index: ${props => props.theme.zIndex.overlay};
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover; /* this might become a problem in IE Edge because it's not supported but with current video i'm not seeing any problems */
`;

const Home: React.FC = () => {
    const dispatch = useDispatch<Dispatch>();
    const redirectTo = useDetermineArticleToRedirectTo();

    const videoRef = createRef<HTMLVideoElement>();
    const { hasEnded, videoHasPlayedBefore } = useDetermineIntroVideoHasPlayed(
        videoRef
    );

    useFetchDeclaration(dispatch);

    if (!redirectTo) {
        return null;
    }

    if (videoHasPlayedBefore || hasEnded) {
        return <Redirect to={createArticleDetailPath(redirectTo)} />;
    }

    const renderVideoSource = () => {
        const screenOrientationPortrait = window.matchMedia(
            '(orientation: portrait)'
        );

        // ideally we would render both sources and let browser detect the orientation but this is not supported in chrome and safari
        if (screenOrientationPortrait.matches) {
            // portrait video
            return (
                <source src="https://player.vimeo.com/progressive_redirect/playback/1073124052/rendition/1080p/file.mp4?loc=external&signature=805634fb0511faa8f3e64eeef9575bd29dcfc78eee75a4db987486c6b937233d" />
            );
        }

        // landscape video
        return (
            <source src="https://player.vimeo.com/progressive_redirect/playback/1073124039/rendition/1440p/file.mp4?loc=external&signature=03e486467005d28c9f0ae74e85f9a30a8f8fa127b1146a1e0777a1a644cafee9" />
        );
    };

    return (
        <StyledVideo ref={videoRef} muted autoPlay playsInline>
            {renderVideoSource()}
        </StyledVideo>
    );
};

export default Home;
